import { AxiosResponse } from 'axios'
import { HasIri } from '@/models/interface/HasIri'

export class ProductMember implements HasIri {
  id?: string
  userIri: string
  productIri: string
  isOwner: boolean

  constructor(data: any, uri?: string) {
    this.id = uri
    this.userIri = data.user
    this.productIri = data.product
    this.isOwner = data.isOwner
  }

  iri(): string {
    return this.id ? this.id : ''
  }

  static createFromResponse(response: AxiosResponse) {
    return new ProductMember(response.data, response.data['@id'])
  }

  static createFromData(data: any) {
    return new ProductMember(data, data['@id'])
  }

  static createFromProductMember(productMember: ProductMember) {
    return new ProductMember(
      {
        userIri: productMember.userIri,
        productIri: productMember.productIri,
        isOwner: productMember.isOwner
      },
      productMember.id
    )
  }
}
