var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('h1',{attrs:{"data-cy":"product-headline"}},[_vm._v(_vm._s(_vm.$t('product.headline')))])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createProduct)}}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('product.createNew.title')))]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"name-of-product","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"filled":"","label":_vm.$t('product.form.name'),"error-messages":errors[0]},model:{value:(_vm.formDataCreateProduct.name),callback:function ($$v) {_vm.$set(_vm.formDataCreateProduct, "name", $$v)},expression:"formDataCreateProduct.name"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":invalid}},[_vm._v(_vm._s(_vm.$t('product.form.submit')))])],1)],1)],1)]}}])})],1),_c('v-col',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.joinProduct)}}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('product.joinExisting.title')))]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"id-of-product","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"filled":"","label":"Join Code","error-messages":errors[0]},model:{value:(_vm.formDataJoinProduct.joinCode),callback:function ($$v) {_vm.$set(_vm.formDataJoinProduct, "joinCode", $$v)},expression:"formDataJoinProduct.joinCode"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":invalid}},[_vm._v(_vm._s(_vm.$t('product.form.submit')))])],1)],1)],1)]}}])})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-img',{attrs:{"max-width":"250","src":require("../../../assets/just-scrum.png")}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }