




























































import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'
import router from '@/router'
import { Product } from '@/models/Product.model'
import { ProductMember } from '@/models/ProductMember.model'

@Component
export default class CreateOrJoinProduct extends Vue {
  @Prop()
  private joinCode: string | undefined

  private isLoading = false
  private formDataCreateProduct = {
    name: ''
  }
  private formDataJoinProduct = {
    joinCode: ''
  }

  created() {
    if (this.joinCode) {
      this.formDataJoinProduct.joinCode = this.joinCode
    }
  }

  async createProduct() {
    try {
      this.isLoading = true
      const productResponse = await axios.post('/products', this.formDataCreateProduct)
      this.$store.commit('product/setCurrentProduct', Product.createFromResponse(productResponse))
      location.reload()
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  async joinProduct() {
    try {
      this.isLoading = true
      const productMember = ProductMember.createFromResponse(
        await axios.post('/product_members', this.formDataJoinProduct)
      )
      this.$store.commit(
        'product/setCurrentProduct',
        Product.createFromResponse(await axios.get(productMember.productIri))
      )
      await router.push({ name: 'Home' })
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }
}
